<!--
 * @Author: HZH
 * @Date: 2021-09-16 14:16:01
 * @LastEditors: HZH
 * @LastEditTime: 2021-09-17 15:34:07
 * @Description: 
-->
<template>
	<div class="header">
		<div class="header_top">
			<div class="wrapper">
				<div class="left" style="display: flex;align-items: center;"><img style="margin-right: 5px;" src="logo.jpg" alt="">小禹智播APP欢迎你</div>
				<div class="right">中山市小禹哥传媒技术有限公司</div>
			</div>
		</div>
		<div class="header_bottom">
			<div class="wrapper">
				<div class="logo" @click="toHome">
					<!-- <img src="/src/assets/banner1.png" alt="" /> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		get
	} from '../../utils/request'
	export default {
		data() {
			return {
				category: [{
						id: 1,
						name: "首页",
					},
					{
						id: 2,
						name: "关于我们",
					}
				],
				// categorys:{

				// }
			}
		},
		methods: {
			getCategorydata() {
				get('/index/category/findAll').then(res => {
					// console.log(res);
					this.category = res.data
					// console.log(this.category);
				})
			},
			toList(c) {
				if(c.id == 1){
					this.$router.push(
						{
							// 要跳转的路由
							path: '/home',
							query: c
							// 要传递的参数
						}
					)
				}else if(c.id == 2){
					this.$router.push(
						{
							// 要跳转的路由
							path: '/about',
							query: c
							// 要传递的参数
						}
					)
				}
				
			},
			toHome() {
				this.$router.push('/')
			}
		},
		created() {
			// this.getCategorydata();
		},
	};
</script>

<style lang="scss" scoped>
	.header {
		.header_top {
			// background-color: #528c38;
			margin-top: 10px;
			.wrapper {
				// color: white;
				font-size: 16px;
				font-weight: 600;

				.left {
					float: left;
					img{
						width:25px;
						height:25px;
					}
				}

				.right {
					float: right;
				}
			}

			.wrapper::after {
				display: block;
				content: "";
				clear: both;
			}
		}

		.header_bottom {
			margin-top: 15px;

			.wrapper {
				display: flex;
				justify-content: space-between;

				.logo {
					flex: 1;

					img {
						width: 200px;
					}
				}

				.menu {
					flex: 2;

					li {
						float: left;
						margin-left: 20px;
						line-height: 35px;
						padding: 0.5em;
						font-size: 20px;
						cursor: pointer;
					}
				}
			}
		}
	}
</style>